import 'bootstrap/dist/js/bootstrap.bundle';
import '../styles/index.scss';

//home-video
let video = document.getElementById('home-video');
window.addEventListener('scroll', () => {

    if (scrollY > video.getBoundingClientRect().y) {
        video.play();
    }
});
